@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;1,800&family=Raleway:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;1,800");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;

  background-color: #222222;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
  -webkit-transition: background-size 0.2s ease-in-out;
  -moz-transition: background-size 0.2s ease-in-out;
  -ms-transition: background-size 0.2s ease-in-out;
  -o-transition: background-size 0.2s ease-in-out;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(#3d5060, #4f5960);
}

.link-underline:hover {
  background-size: 100% 3px;
  background-position: 0 100%;
}

.link-dashboardText {
  box-shadow: inset 0 0 0 0 #4f5960;
  margin: 0 -0.25rem;
  padding: 0 0.25rem;
  transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.link-dashboardText:hover {
  box-shadow: inset 200px 0 0 0 #4f5960;
  color: white;
  border-radius: 1px 5px 0px 0px !important;
}

.slick-dots > ul > li {
  border: 1px solid white !important;
  border-radius: 1px 5px 0px 0px !important;
  -webkit-border-radius: 1px 5px 0px 0px !important;
  -moz-border-radius: 1px 5px 0px 0px !important;
  -ms-border-radius: 1px 5px 0px 0px !important;
  -o-border-radius: 1px 5px 0px 0px !important;
}

.slick-dots li.slick-active button:before {
  background-color: white !important;
  color: white !important;
  border-radius: 1px 5px 0px 0px !important;
}

.slick-dots {
  bottom: 0px !important;
}
